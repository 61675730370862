import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import {
  useAssignPackageMutation,
  useAssignPackageToMemberMutation,
  useCreateCompanyMutation,
  useGetCompanyUsersQuery,
  useGetFloorRoomsForMemberQuery,
  useGetUnassignedResourcesQuery,
  useMultiplePackagesCompanyMutation,
} from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import AsignPackage from "./AsignPackage";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AssignOccupancy from "./AssignOccupancy";
const AddCompanyPopup = ({
  FetchPackageData,
  FetchMeetingPackageData,
  FloorPlans,
  SpaceTypes,
}) => {
  const { membersPermissions, currency } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createCompany, resultStats] = useCreateCompanyMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const { data: FetchResources, isLoading: resourceLoading } =
    useGetUnassignedResourcesQuery({ spaceId: spaceId || currentSpaceId });
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const [assignPackage, resultAssign] = useAssignPackageToMemberMutation();
  const [file, setFile] = useState("");
  const [floor, setFloor] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomType, setRoomType] = useState("");
  const handleRoomChange = (e) => {
    setRoomId(e.target.value);
  };
  const handleFloorChange = (e) => {
    setFloor(e.target.value);
  };
  const handleRoomType = (e) => {
    setRoomType(e.target.value);
  };
  // const { data: floorRooms, isLoading: usersLoading } =
  //   useGetFloorRoomsForMemberQuery(
  //     {
  //       floorId: floor,
  //       room_type: roomType,
  //     },
  //     {
  //       skip: floor === null || roomType === null,
  //       refetchOnMountOrArgChange: true,

  //     }
  //   );
  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    email: "",
    password: "",
    profileImg: "",
    cnicNo: "",
    phoneNo: "",
    address: "",
    contactPerson: "",
    securityDeposit: 0,
    spaceId: spaceId || currentSpaceId,
    company: true,
    active: 1,
  });

  const [data, setData] = useState({
    meetingPackageIds: [
      {
        packageId: 1,
        quantity: 1,
        isRecurring: false,
      },
    ],
    spacePackageIds: [
      {
        packageId: 1,
        quantity: 1,
        isRecurring: false,
      },
    ],
    startTime: getCurrentDate(),
    meetingPackageIds: [],
    carryForward: "",
    companyShare: "",
    discount: 0,
  });
  const resetForm = () => {
    setCompanyInfo({
      name: "",
      email: "",
      password: "",
      profileImg: "",
      cnicNo: "",
      phoneNo: "",
      address: "",
      contactPerson: "",
      securityDeposit: "",
      spaceId: spaceId || currentSpaceId,
      company: true,
    });
    setData({
      packageId: FetchPackageData?.[0]?.id,
      spacePackageIds: [],
      startTime: getCurrentDate(),
      meetingPackageIds: [],
      carryForward: "",
      companyShare: "",
      discount: 0,
    });
    setCompanyResponse("");
    setFile("");
  };

  const handleDiscountChange = (event) => {
    setData({ ...data, discount: event.target.value });
  };
  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);

      if (file.size >= 1e7) {
        setIsUploading(false);

        errorAlertImage("File is too big!");
        setFile(null);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUploadedImageUrls(resp.data?.[0]);
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);
            setFile(null);
          });
      } else {
        setFile(null);
        setIsUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [file]);
  useEffect(() => {
    setCompanyInfo({
      ...companyInfo,
      profileImg: uploadedImageUrls,
    });
  }, [uploadedImageUrls]);

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const [companyResponse, setCompanyResponse] = useState(null);

  const [show, setShow] = useState(false);
  const [cnicNumber, setCnicNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setIsUploading(false);
    setFile("");
    setShow(false);
    resetForm();
    setFloor("");
    setRoomId("");
    setRoomType("");
    setRows([
      {
        packageId: 0,
        quantity: 1,
        isRecurring: false,
        resources: [],
      },
    ]);
    setMeetingRows([
      {
        packageId: 0,
        quantity: 1,
        isRecurring: false,
      },
    ]);
  };
  const handleShow = () => setShow(true);

  const [rows, setRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],
    },
  ]);
  const updateRowResources = (selectedResources, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].resources = selectedResources?.map(
      (resource) => resource.id
    );
    setRows(updatedRows);
  };

  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
    },
  ]);

  const handleAddRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],

      // price: FetchPackageData?.[0]?.price,
      // amount: FetchPackageData?.[0]?.price,
    };
    setRows([...rows, newRow]);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,

      // price: FetchMeetingPackageData?.[0]?.price,
      // amount: FetchMeetingPackageData?.[0]?.price,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  // const handleInputChange = (event, index) => {
  //   let data = [...rows];
  //   data[index][event.target.name] = event.target.value;
  //   setRows(data);
  // };
  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index].packageId = event.id;
    setRows(data);
  };
  const handleRowQuantity = (event, index) => {
    let data = [...rows];
    data[index].quantity = event.target.value;
    setRows(data);
  };
  const handlePackageRecurring = (event, index) => {
    let data = [...rows];
    data[index].isRecurring = !data[index].isRecurring;
    setRows(data);
  };
  const handleMeetingPackageRecurring = (event, index) => {
    let data = [...meetingRows];
    data[index].isRecurring = !data[index].isRecurring;
    setMeetingRows(data);
  };

  // const handleMeetingInputChange = (event, index) => {
  //   let data = [...meetingRows];
  //   data[index][event.target.name] = event.target.value;
  //   setMeetingRows(data);
  // };

  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index].packageId = event.id;
    setMeetingRows(data);
  };
  const handleMeetingQuantityChanged = (event, index) => {
    let data = [...meetingRows];
    data[index].quantity = event.target.value;
    setMeetingRows(data);
  };
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      setTimeout(() => {
        setCompanyInfo({
          name: "",
          email: "",
          password: "",
          profileImg: "",
          cnicNo: "",
          phoneNo: "",
          address: "",
          contactPerson: "",
          securityDeposit: "",

          spaceId: spaceId || currentSpaceId,
        });
      }, 1500);
      if (resultStats?.data?.id) {
        setCompanyResponse(resultStats?.data?.id);
      } else {
        setCompanyResponse(resultStats?.data?.companyId);
      }
    }
  }, [resultStats]);
  const navigate = useNavigate();
  useEffect(() => {
    if (resultAssign?.isError) {
      setApiSpinner(false);

      requiredAlert(resultAssign?.error?.data?.message);
    }

    if (resultAssign?.isSuccess) {
      setApiSpinner(false);

      setTimeout(() => {
        resetForm();
      }, 1500);
      handleClose();

      toast.success("Company Created Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/member/list");
    }
  }, [resultAssign]);

  const postCompany = (e) => {
    e.preventDefault();

    let {
      name,
      email,
      password,
      profileImg,
      cnicNo,
      spaceId,
      phoneNo,
      address,
      company,
      active,
      contactPerson,
      securityDeposit,
    } = companyInfo;

    if (cnicNo?.length !== 13) {
      setErrorMessage("Invalid CNIC");
      return; // Prevent form submission
    }

    // if (!profileImg) {
    //   setErrorMessage("No File");
    //   return;
    // }
    if (!name) {
      setErrorMessage("No Name");
      return;
    }
    if (!contactPerson) {
      setErrorMessage("No Person");
      return;
    }
    if (!phoneNo || phoneNo.length < 11 || phoneNo.length > 15) {
      setErrorMessage("No Phone");
      return;
    }

    setErrorMessage("");

    let updateData = {
      name,
      email,
      password,
      profileImg: profileImg || "",
      cnicNo,
      contactPerson,
      securityDeposit,
      spaceId: parseInt(spaceId),
      phoneNo,
      active,
      address,
      company,
      room: parseInt(roomId),
    };
    console.log("Updated data is", updateData);
    setApiSpinner(true);

    createCompany({ spaceId: spaceId || currentSpaceId, data: updateData });
  };

  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
    setErrorMessage("");
  };

  const handleCnic = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setCnicNumber(numericValue);
    setCompanyInfo({ ...companyInfo, [e.target.name]: numericValue });
  };

  const handleInfo = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const handleActive = (e) => {
    setCompanyInfo({ ...companyInfo, active: e.id });
  };
  const handleChange = (event) => {
    setData({ ...data, startTime: event.target.value });
  };

  const postAsignPackage = (e) => {
    e.preventDefault();
    const { spacePackageIds, meetingPackageIds, discount, startTime } = data;
    let timeStampStartTime = toTimestamp(startTime);

    // const updateSpacePackageIds = rows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));

    // const updateMeetingPackageIds = meetingRows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));

    const updateSpacePackageIds = rows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
        resources: item.resources,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    const updateMeetingPackageIds = meetingRows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    let updateData = {
      member: companyResponse,
      space: spaceId || currentSpaceId,
      packages: updateSpacePackageIds,
      meeting_packages: updateMeetingPackageIds,
      discount: parseInt(discount),
      start_date: startTime,
    };
    setApiSpinner(true);
    assignPackage({
      spaceId: spaceId || currentSpaceId,
      userId: companyResponse,
      data: updateData,
    });
  };

  const handle = (e) => {
    setData({
      ...data,
      spacePackageIds: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  const handleMeeting = (e) => {
    setData({
      ...data,
      meetingPackageIds: Array.isArray(e) ? e.map((x) => x.id) : [],
    });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn"
      >
        {membersPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Add Company
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Add Company
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fade
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h3">Create a Company</Modal.Title>
        </Modal.Header>
        <form onSubmit={postCompany}>
          <Modal.Body>
            <section>
              <div className={`${companyResponse ? "d-none" : ""}`}>
                <div className="form-group">
                  <label htmlFor="name">Company Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Teczon Labs"
                    value={companyInfo.name}
                    // required
                    // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    onChange={handleInfo}
                  />
                  {errorMessage === "No Name" && (
                    <p style={{ color: "red" }}>*Enter Company Name</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="name">Email</label>
                  <input
                    type="email"
                    name="email"
                    required
                    className="form-control"
                    placeholder="johndoe@gmail.com"
                    value={companyInfo.email}
                    onChange={handleInfo}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Contact Person</label>
                  <input
                    type="text"
                    name="contactPerson"
                    className="form-control"
                    placeholder="John Doe"
                    value={companyInfo.contactPerson}
                    // required
                    // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    onChange={handleInfo}
                  />
                  {errorMessage === "No Person" && (
                    <p style={{ color: "red" }}>*Enter Contact Person Name</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    // name="phoneNo"
                    value={companyInfo.phoneNo}
                    // onChange={handleInfo}
                    // min={0}
                    required
                    // pattern="\d{11,15}"
                    onChange={(e) => {
                      setCompanyInfo({
                        ...companyInfo,
                        phoneNo: e,
                      });
                    }}
                  />
                  {errorMessage === "No Phone" && (
                    <p style={{ color: "red" }}>*Enter 11-15 Digits Phone No</p>
                  )}

                  <span className="error">
                    Write 11-15 digits phone number!
                  </span>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="phoneNo">Phone</label>

                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter 11-15 Digits Phone Number"
                    name="phoneNo"
                    value={companyInfo.phoneNo}
                    onChange={handleInfo}
                    min={0}
                    required
                    // onBlur={handleFocus}
                    // pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                    // focused={validation.phoneNo.toString()}
                    pattern="\d{11,15}"
                    // onKeyDown={(e) => {
                    //   const allowedKeys = ["Backspace", "Delete"];
                    //   if (
                    //     !/[0-9]/.test(e.key) &&
                    //     !allowedKeys.includes(e.key)
                    //   ) {
                    //     e.preventDefault();
                    //   }
                    // }}
                    // focused={validation.phoneNo.toString()}
                  /> */}
                {/* {errorMessage === "No Phone" && (
                    <p style={{ color: "red" }}>*Enter 11-15 Digits Phone No</p>
                  )}

                  <span className="error">
                    Write 11-15 digits phone number!
                  </span> */}
                {/* </div> */}

                <div className="form-group">
                  <label htmlFor="name">ID Card Number</label>
                  <input
                    type="text"
                    required
                    name="cnicNo"
                    className="form-control"
                    placeholder="xxxxx-xxxxxxx-x"
                    value={cnicNumber}
                    onChange={handleCnic}
                  />
                  {errorMessage === "Invalid CNIC" && (
                    <p style={{ color: "red" }}>
                      *Enter 13 Digits ID Card Number
                    </p>
                  )}
                </div>

                {/* <div className="form-group">
                  <label htmlFor="phoneNo">Security Deposit</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Ammount"
                    name="securityDeposit"
                    value={companyInfo.securityDeposit}
                    onChange={handleInfo}
                    min={0}
                    required
                  />
                </div> */}

                <div className="form-group">
                  <label htmlFor="phoneNo">Security Deposit</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{currency}</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Amount"
                      name="securityDeposit"
                      value={companyInfo.securityDeposit}
                      onChange={handleInfo}
                      min={0}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="address">
                    Address <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    placeholder="ABC Street, XYZ Country"
                    value={companyInfo.address}
                    // required
                    // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    onChange={handleInfo}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="address">Status</label>
                  <select
                    name="active"
                    className="form-control"
                    value={companyInfo?.active}
                    onChange={handleInfo}
                  >
                    <option value={1}>Active</option>
                    <option value={2}>Inactive</option>
                  </select>
                </div>

                <div className="form-group">
                  <label className="fs-6 mb-2">
                    Profile Picture <span className="optional">(Optional)</span>
                  </label>
                  <div className="media">
                    <div className="media-aside align-self-start">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      />
                    </div>
                    <div>
                      <input
                        id="real-file"
                        name="file"
                        type="file"
                        hidden="hidden"
                        // required
                        onChange={(event) => setFile(event.target.files[0])}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={uploadFile}
                          className="btn btn-secondary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        &nbsp; Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                    </div>
                  </div>
                  {/* {errorMessage === "No File" && (
                    <p style={{ color: "red" }}>*Please Select a File</p>
                  )} */}
                </div>

                {/* <div className="form-group">
                  <label htmlFor="address">Floor</label>
                  <select
                    name=""
                    id="employee"
                    className="form-control"
                    onChange={(e) => handleFloorChange(e)}
                    value={floor}
                  >
                    <option value="" disabled>
                      Please Select Floor
                    </option>
                    {FloorPlans?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="address">Room Type</label>
                  <select
                    name=""
                    id="employee"
                    className="form-control"
                    onChange={(e) => handleRoomType(e)}
                    value={roomType}
                  >
                    <option value="" disabled>
                      Please Select Room Type
                    </option>

                    <option value={1}>Dedicated Desk</option>
                    <option value={3}>Private Office</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="address">Available Rooms</label>
                  <select
                    name=""
                    id="employee"
                    className="form-control"
                    onChange={(e) => handleRoomChange(e)}
                    value={roomId}
                  >
                    <option value="" disabled>
                      Please Select Available Room
                    </option>
                    {floorRooms?.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>

              {companyResponse && (
                <>
                  <div className="form-group">
                    <label htmlFor="startTime">Joining Date:</label>
                    <div className="input-group ">
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          <Icon.Calendar2Check />
                        </span>
                      </div>
                      <input
                        type="date"
                        name="startTime"
                        id="startTime"
                        className="form-control"
                        value={data?.startTime}
                        onChange={handleChange}
                        min={getCurrentDate()}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="package-facilities" className="mb-1">
                      Assign Space Package(s)
                    </label>
                    {FetchPackageData?.length > 0 &&
                      rows?.map((input, index) => (
                        <div key={index} className="repeater-wrapper my-2">
                          <div className="">
                            <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0 pe-3">
                                <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                  <p className="card-text col-title mb-md-50 mb-0">
                                    Select Package to Assign
                                  </p>
                                  {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) => handleInputChange(e, index)}
                                  className="form-control"
                                >
                                  {FetchPackageData?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </div> */}
                                  <Select
                                    name="packageId"
                                    options={FetchPackageData}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={colorStyles}
                                    value={FetchPackageData?.find(
                                      (obj) =>
                                        obj.id === rows?.[index]?.packageId
                                    )}
                                    onChange={(selectedOption) =>
                                      handleInputChange(selectedOption, index)
                                    }
                                  />
                                </div>

                                <div className="col-12 my-lg-0 my-2">
                                  <p className="card-text col-title mb-0 mt-2">
                                    Number of Packages/Seats
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="1"
                                    min={1}
                                    name="quantity"
                                    value={input?.quantity}
                                    onChange={(e) =>
                                      handleRowQuantity(e, index)
                                    }
                                  />
                                </div>
                                <div className="form-check mt-3">
                                  <label
                                    className="form-check-label"
                                    htmlFor="recurringPackage"
                                  >
                                    Is this Package Recurring!
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input ml-4"
                                    id="recurringPackage"
                                    checked={input?.isRecurring}
                                    onChange={(e) =>
                                      handlePackageRecurring(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                                <p
                                  className="bg-white"
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <CancelIcon />
                                </p>
                              </div>
                            </div>
                          </div>
                          {input?.packageId ? (
                            <AssignOccupancy
                              packageId={input?.packageId}
                              packageQuantity={input?.quantity}
                              AllSpacePackages={FetchPackageData}
                              // Resources={Resources}
                              Resources={FetchResources}
                              SpaceTypes={SpaceTypes}
                              updateRowResources={(selectedResources) =>
                                updateRowResources(selectedResources, index)
                              }
                              // updateRowResources={updateResourcesCallback}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    {FetchPackageData?.length <= 0 && (
                      <p>No Package is Available</p>
                    )}
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-link send-invoice-btn"
                          data-repeater-create=""
                          onClick={handleAddRow}
                        >
                          Associate More Packages
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="meetingPackageIds" className="mb-1">
                      Assign Meeting Room Package(s)
                    </label>

                    {FetchMeetingPackageData?.length > 0 &&
                      meetingRows?.map((input, index) => (
                        <div key={index} className="repeater-wrapper my-2">
                          <div className="">
                            <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0 pe-3">
                                <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                  <p className="card-text col-title mb-md-50 mb-0">
                                    Select Package to Assign
                                  </p>
                                  {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) =>
                                    handleMeetingInputChange(e, index)
                                  }
                                  className="form-control"
                                >
                                  {FetchMeetingPackageData?.map(
                                    (item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>

                              <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) =>
                                    handleMeetingInputChange(e, index)
                                  }
                                />
                              </div> */}
                                  <Select
                                    name="packageId"
                                    options={FetchMeetingPackageData}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={colorStyles}
                                    value={FetchMeetingPackageData?.find(
                                      (obj) =>
                                        obj.id ===
                                        meetingRows?.[index]?.packageId
                                    )}
                                    onChange={(selectedOption) =>
                                      handleMeetingInputChange(
                                        selectedOption,
                                        index
                                      )
                                    }
                                  />
                                </div>

                                <div className="col-12 my-lg-0 my-2">
                                  <p className="card-text col-title mb-0 mt-2">
                                    Number of Packages/Seats
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="1"
                                    min={1}
                                    name="quantity"
                                    value={input?.quantity}
                                    onChange={(e) =>
                                      handleMeetingQuantityChanged(e, index)
                                    }
                                  />
                                </div>
                                <div className="form-check mt-3">
                                  <label
                                    className="form-check-label"
                                    htmlFor="recurringPackage"
                                  >
                                    Is this Package Recurring!
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input ml-4"
                                    id="recurringPackage"
                                    checked={input?.isRecurring}
                                    onChange={(e) =>
                                      handleMeetingPackageRecurring(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                                <p
                                  className="bg-white"
                                  onClick={() => handleRemoveMeetingRow(index)}
                                >
                                  <CancelIcon />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {FetchMeetingPackageData?.length <= 0 && (
                      <p>No Meeting Package is Available</p>
                    )}
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-link send-invoice-btn"
                          data-repeater-create=""
                          onClick={handleAddMeetingRow}
                        >
                          Associate More Packages
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="discount">
                      Discount (%) <span className="optional">(Optional)</span>
                    </label>
                    <input
                      type="number"
                      name="discount"
                      id="discount"
                      placeholder="Discount %"
                      className="form-control"
                      value={data?.discount}
                      onChange={handleDiscountChange}
                      min={0}
                    />
                  </div>
                </>
              )}

              {!companyResponse && (
                <div className="mt-4">
                  <Button
                    variant="primary"
                    disabled={
                      isUploading ||
                      apiSpinner ||
                      !companyInfo?.name ||
                      !companyInfo?.email ||
                      !companyInfo?.contactPerson ||
                      !companyInfo?.phoneNo ||
                      !companyInfo?.cnicNo ||
                      !companyInfo?.securityDeposit
                    }
                    type="submit"
                  >
                    Add Company{" "}
                    {isUploading || apiSpinner ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </Button>
                  <button
                    className="btn btn-secondary ml-3"
                    disabled={apiSpinner || isUploading}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              )}

              {companyResponse && (
                <Button
                  variant="primary"
                  disabled={apiSpinner}
                  onClick={postAsignPackage}
                >
                  Assign Package{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
              )}
            </section>
          </Modal.Body>
          {/* <Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default AddCompanyPopup;
