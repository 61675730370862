import html2pdf from "html2pdf.js";
import { formatDateNotTime } from "./displayDateTime";

export const downloadPDF = (
  invoice,
  globalTaxRateObj,
  currentGlobalTaxRateObj
) => {
  const invoiceHTML = `
    <div id="invoice" style="margin:0px">
    <div>
    <div style="margin-bottom:40px;">
        <div>
          <h1 style="margin:0px">Invoice</h1>
        </div>
    </div>

    <div style="overflow: hidden; margin-bottom: 40px;">
      <div style="width: 66.6%; float: left;">
        <div>
          ${
            invoice?.space?.name
              ? `<h3 style="margin: 0;">${invoice.space.name}</h3>`
              : ""
          }
          ${
            invoice?.space?.number
              ? `<p style="margin: 0;">${invoice.space.number}</p>`
              : ""
          }
          ${
            invoice?.space?.address
              ? `<p style="margin: 0;">${invoice.space.address}</p>`
              : ""
          }
        </div>
      </div>
      <div style="width: 33.3%; float: left;">
        ${
          invoice?.id
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Invoice ID:</p>
          <b style="float: right;">${invoice.id}</b>
        </div>`
            : ""
        }

        ${
          invoice?.issued_date
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Issued Date:</p>
          <b style="float: right;">${formatDateNotTime(invoice.issued_date)}</b>
        </div>`
            : ""
        }

        ${
          invoice?.due_date
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Due Date:</p>
          <b style="float: right;">${formatDateNotTime(invoice.due_date)}</b>
        </div>`
            : ""
        }

        ${
          invoice?.paid !== undefined
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Paid:</p>
          <b style="float: right;">${invoice.paid ? "Yes" : "No"}</b>
        </div>`
            : ""
        }
      </div>
    </div>

    <div style="overflow: hidden; margin:20px 0px">

      <div style="width: 66.6%; float: left;">
        <div>
          <h3 style="margin: 0;">Invoice To:</h3>
          ${
            invoice?.member?.name
              ? `<h4 style="margin: 0;">${invoice.member.name}</h4>`
              : ""
          }
          ${
            invoice?.member?.email
              ? `<p style="margin: 0;"><b>${invoice.member.email}</b></p>`
              : ""
          }
          ${
            invoice?.member?.phoneNo
              ? `<p style="margin: 0;"><b>${invoice.member.phoneNo}</b></p>`
              : ""
          }
          ${
            invoice?.member?.address
              ? `<p style="margin: 0;"><b>${invoice.member.address}</b></p>`
              : ""
          }
        </div>
      </div>

      <div style="width: 33.3%; float: left;">
        ${
          invoice?.total
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Total Due:</p>
          <b style="float: right;">${invoice.total} ${invoice?.currency?.name}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.bankName
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Bank Name:</p>
          <b style="float: right;">${invoice.space.bankName}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.branchName
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Branch Name:</p>
          <b style="float: right;">${invoice.space.branchName}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.iban
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">IBAN:</p>
          <b style="float: right;">${invoice.space.iban}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.swiftCode
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Swift Code:</p>
          <b style="float: right;">${invoice.space.swiftCode}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.accountNumber
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Account Number:</p>
          <b style="float: right;">${invoice.space.accountNumber}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.institutionNumber
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Institution Number:</p>
          <b style="float: right;">${invoice.space.institutionNumber}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.branchCode
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Branch Code:</p>
          <b style="float: right;">${invoice.space.branchCode}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.routingNumber
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Routing Number:</p>
          <b style="float: right;">${invoice.space.routingNumber}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.transitNumber
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Transit Number:</p>
          <b style="float: right;">${invoice.space.transitNumber}</b>
        </div>`
            : ""
        }

        ${
          invoice?.space?.taxNumber
            ? `
        <div style="overflow: hidden;">
          <p style="margin: 0; float: left;">Tax Number:</p>
          <b style="float: right;">${invoice.space.taxNumber}</b>
        </div>`
            : ""
        }
      </div>
    </div>

    <!-- Packages Table -->
    ${
      invoice.assignedPackage?.packages?.length > 0
        ? `
          <div style="margin-bottom: 20px">
            <h3>Packages</h3>
            <table>
              <thead align="center" style="padding: 5px;">
                <tr>
                  <th width="250px" style="padding: 5px;">Name</th>
                  <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;">Charges</th>
                  <th width="250px" style="padding: 5px;">Quantity</th>
                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.packages
                  .map(
                    (pkg) => `
                    <tr key=${pkg.id} style="padding: 5px">
                      <td style="padding: 5px;">${pkg.package.name}</td>
                      <td style="padding: 5px;">
                        ${
                          invoice?.assignedPackage?.expiry_duration === 1
                            ? "Daily"
                            : invoice.assignedPackage?.expiry_duration === 2
                            ? "Weekly"
                            : "Monthly"
                        }
                      </td>
                      <td style="padding: 5px;">${pkg.package.rentalFee}</td>
                      <td style="padding: 5px;">${
                        pkg.package.serviceCharges
                      }</td>
                      <td style="padding: 5px;">${pkg.quantity}</td>
                    </tr>
                  `
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
        `
        : ""
    }

    <!-- Meeting Packages Table -->
    ${
      invoice.assignedPackage?.meeting_packages?.length > 0
        ? `
          <div style="margin-bottom: 20px">
            <h3>Meeting Packages</h3>
            <table>
              <thead style="padding: 5px;">
                <tr align="center">
                  <th width="250px" style="padding: 5px;"> Name</th>
                  <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;"> Charges</th>
                  <th width="250px" style="padding: 5px;"> Quantity</th>
                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.meeting_packages
                  .map(
                    (meetingPkg) => `
                    <tr key=${meetingPkg.id}>
                      <td style="padding: 5px;">${meetingPkg.package.name}</td>
                      <td style="padding: 5px;">
                        ${
                          invoice?.assignedPackage?.expiry_duration === 1
                            ? "Daily"
                            : invoice.assignedPackage?.expiry_duration === 2
                            ? "Weekly"
                            : "Monthly"
                        }
                      </td>
                      <td style="padding: 5px;">${
                        meetingPkg.package.rentalFee
                      }</td>
                      <td style="padding: 5px;">${
                        meetingPkg.package.serviceCharges
                      }</td>
                      <td style="padding: 5px;">${meetingPkg.quantity}</td>
                    </tr>
                  `
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
        `
        : ""
    }


       <!-- Purchases Table -->
       ${
         invoice.assignedPackage?.purchase
           ? `
            <div style="margin-bottom: 20px;">
              <h3>Purchases</h3>
              <table>
                <thead style="padding: 5px;">
                  <tr align="center">
                    <th style="padding: 5px; width: 250px;">Name</th>
                    <th style="padding: 5px; width: 250px;">Price</th>
                    <th style="padding: 5px; width: 250px;">Quantity</th>
                    <th style="padding: 5px; width: 250px;">Tax</th>
                  </tr>
                </thead>
                <tbody align="center">
                  ${invoice.assignedPackage.purchase?.items
                    .map(
                      (purchase) => `
                      <tr key=${purchase.id} >
                        <td style="padding: 5px;">${purchase.product.name}</td>
                        <td style="padding: 5px;">${purchase.product.price}</td>
                        <td style="padding: 5px;">${
                          purchase.quantity === 0 ? 1 : purchase.quantity
                        }</td>
                        <td style="padding: 5px;">
                          ${purchase.product.inclusiveTax ? "No Tax" : ""}
                          ${
                            !purchase.product.inclusiveTax &&
                            purchase.taxRateObj?.setOveralTax
                              ? `
                              ${
                                purchase.taxRateObj.serviceCharges
                                  ? `
                                <div>
                                  <span>Service: ${
                                    purchase.taxRateObj.serviceCharges || 0
                                  }%</span>
                                </div>
                              `
                                  : `
                                <div>
                                  <div>Rent Fee: ${
                                    purchase.taxRateObj.rentalFee || 0
                                  }%</div>
                                  <div>Service: ${
                                    purchase.taxRateObj.serviceCharges || 0
                                  }%</div>
                                </div>
                              `
                              }
                            `
                              : ""
                          }
                          ${
                            !purchase.product.inclusiveTax &&
                            !purchase.taxRateObj?.setOveralTax
                              ? `
                              ${
                                globalTaxRateObj?.setOverallTax ||
                                currentGlobalTaxRateObj?.setOverallTax
                                  ? `
                                <div>
                                  <span>Service: ${
                                    globalTaxRateObj?.serviceCharges ||
                                    currentGlobalTaxRateObj?.serviceCharges ||
                                    0
                                  }%</span>
                                </div>
                              `
                                  : `
                                <div>
                                  <div>Rent Fee: ${
                                    globalTaxRateObj?.rentalFee ||
                                    currentGlobalTaxRateObj?.rentalFee ||
                                    0
                                  }%</div>
                                  <div>Service: ${
                                    globalTaxRateObj?.serviceCharges ||
                                    currentGlobalTaxRateObj?.serviceCharges ||
                                    0
                                  }%</div>
                                </div>
                              `
                              }
                            `
                              : ""
                          }
                        </td>
                      </tr>
                    `
                    )
                    .join("")}
                </tbody>
              </table>
            </div>
          `
           : ""
       }

       <div style="overflow: hidden; margin: 40px 0px 0px 0px;">
  <div style="float: left; width: 66.6%;">
    ${
      invoice?.space?.invoiceInstructions
        ? `<p style="white-space: pre-line; margin: 0;">${invoice?.space?.invoiceInstructions}</p>`
        : ``
    }
  </div>

  <div style="float: right; width: 33.3%;">
    <div style="width: 100%; overflow: hidden;">
      <p style="float: left; margin: 0;">Sub Total:</p>
      <b style="float: right;">${invoice?.subtotal} ${
    invoice?.currency?.name
  }</b>
    </div>

    ${
      invoice?.type === 2 || invoice?.type === 6
        ? `
      <div style="width: 100%; overflow: hidden;">
        <div style="width: 100%; overflow: hidden;">
          ${
            invoice?.setOveralTax
              ? `
            <p style="float: left; margin: 0;">Overal Tax:</p>
            <b style="float: right; font-weight: bold; margin: 0;">${invoice?.serviceCharges} %</b>
            `
              : `
            <p style="float: left; margin: 0;">Service Charges:</p>
            <b style="float: right; font-weight: bold; margin: 0;">${invoice?.serviceCharges} %</b>
            `
          }
        </div>

        <div style="width: 100%; overflow: hidden;">
          <p style="float: left; margin: 0;">${
            invoice?.setOveralTax ? "" : "Rental Fee:"
          }</p>
          <b style="float: right; font-weight: bold; margin: 0;">${
            invoice?.setOveralTax ? "" : `${invoice?.rentalFee} %`
          }</b>
        </div>
      </div>
      `
        : `
      <div style="width: 100%; overflow: hidden;">
        <p style="float: left; margin: 0;">Total Tax:</p>
        <b style="float: right;">${invoice?.totalTax} ${invoice?.currency?.name}</b>
      </div>
      `
    }

    <div style="width: 100%; overflow: hidden;">
      <p style="float: left; margin: 0;">Discount:</p>
      <b style="float: right;">${invoice?.discount_percentage} %</b>
    </div>

    <div style="width: 100%; overflow: hidden;">
      <p style="float: left; margin: 0;">Adjustment:</p>
      <b style="float: right;">${invoice?.adjustment}</b>
    </div>

    <hr style="width: 100%;" />

    <div style="width: 100%; overflow: hidden;">
      <p style="float: left; margin: 0;">Total:</p>
      <b style="float: right;">${invoice?.total?.toFixed(2)} ${
    invoice?.currency?.name
  }</b>
    </div>
  </div>

  <div style="clear: both;"></div>
</div>


    </div>
    </div>
`;

  const pdfOptions = {
    filename: `INV—${formatDateNotTime(invoice.due_date)}.pdf`, // Specify the desired file name
    margin: 20,
    html2canvas: { scale: 2 },
    jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  };

  html2pdf().from(invoiceHTML).set(pdfOptions).save();
  // html2pdf().from(invoiceHTML).save();
};
