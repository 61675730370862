import React, { useState } from "react";
import { Select } from "./";
import { colorStyles } from "../../utils/constant";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { Toaster, toast } from "react-hot-toast";
import { useEffect } from "react";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useGetCitiesQuery, useGetStatesQuery } from "../../Slices/constantApi";

const TabInformation = ({ spaceData, Country }) => {
  const { settingsPermissions, geoLocation, setLocationCall, locationCall } =
    useGlobalContext();
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (geoLocation?.components?.country) {
      setLocationCall(false);
    } else {
      setLocationCall(true);
    }
  }, [geoLocation]);

  const [informationData, setInformationData] = useState({
    city: null,
    number: spaceData?.number,
    // description: spaceData?.description,
    bankName: spaceData?.bankName,
    branchName: spaceData?.branchName,
    iban: spaceData?.iban,
    country: null,
    countryName: spaceData?.countryName || geoLocation.country || Country[0],
    stateName: spaceData?.stateName || geoLocation.state || null,
    cityName:
      spaceData?.cityName ||
      geoLocation.city ||
      geoLocation.components.district ||
      null,
    swiftCode: spaceData?.swiftCode,
    address: spaceData?.address,
    accountNumber: spaceData?.accountNumber,
    institutionNumber: spaceData?.institutionNumber,
    branchCode: spaceData?.branchCode,
    routingNumber: spaceData?.routingNumber,
    transitNumber: spaceData?.transitNumber,
    taxNumber: spaceData?.taxNumber,
  });

  const { data: states, isLoading: statesLoading } = useGetStatesQuery(
    {
      countryName: informationData?.countryName,
    },
    {
      skip: informationData?.countryName == null || !change,
      refetchOnMountOrArgChange: true,
      // query: {
      //   isOccupies: "False",
      // },
    }
  );

  const { data: Cities, isLoading: citiesLoading } = useGetCitiesQuery(
    {
      stateName: informationData?.stateName,
    },
    {
      skip: informationData?.stateName == null || !change,
      refetchOnMountOrArgChange: true,
      // query: {
      //   isOccupies: "False",
      // },
    }
  );

  const [numberValid, setNumberValid] = useState(true);
  // const [descriptionValid, setDescriptionValid] = useState(true);
  const [bankNameValid, setbankNameValid] = useState(true);
  const [branchNameValid, setBranchNameValid] = useState(true);
  const [ibanValid, setIbanValid] = useState(true);
  const [swiftCodeValid, setSwiftCodeValid] = useState(true);
  const [addressValid, setAddressValid] = useState(true);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateInformation, resultStats] = useUpdateSpaceMutation();

  const handle = (e) => {
    setChange(true);
    setInformationData({ ...informationData, [e.target.name]: e.target.value });

    switch (e.target.name) {
      case "address":
        setAddressValid(e.target.validity.valid);
        break;
      case "bankName":
        setbankNameValid(e.target.validity.valid);
        break;
      case "number":
        setNumberValid(e.target.validity.valid);
        break;
      // case "description":
      //   setDescriptionValid(e.target.validity.valid);
      //   break;
      case "branchName":
        setBranchNameValid(e.target.validity.valid);
        break;
      case "iban":
        setIbanValid(e.target.validity.valid);
        break;
      case "swiftCode":
        setSwiftCodeValid(e.target.validity.valid);
        break;
      default:
        break;
    }
  };

  const handleCity = (e) => {
    setInformationData({ ...informationData, cityName: e.value });
    setChange(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // The name attribute is simply "stateName," so no need to split it.
    setInformationData((prevState) => ({
      ...prevState,
      [name]: value, // Update stateName directly
    }));
  };
  useEffect(() => {
    if (states?.length > 0) {
      setInformationData((prevState) => ({
        ...prevState,
        stateName: states?.[0], // Update stateName directly
      }));
    }
  }, [states]);
  useEffect(() => {
    if (Cities?.length > 0) {
      setInformationData((prevState) => ({
        ...prevState,
        cityName: Cities?.[0], // Update stateName directly
      }));
    }
  }, [Cities]);

  const handleCountry = (event) => {
    setInformationData({
      ...informationData,
      stateName: null,
      cityName: null, // Set cityName to null
      countryName: event.target.value,
    });
    setChange(true);
  };
  const handleState = (e) => {
    setInformationData({ ...informationData, stateName: e.value });
    setChange(true);
  };

  const postInformation = (e) => {
    e.preventDefault();

    if (
      // !informationData?.city ||
      !informationData?.number ||
      // !informationData?.description ||
      // !informationData?.bankName ||
      // !informationData?.branchName ||
      // !informationData?.iban ||
      !informationData?.countryName ||
      !informationData?.stateName ||
      !informationData?.cityName ||
      // !informationData?.swiftCode ||
      !informationData?.address
    ) {
      return;
    }

    updateInformation({
      spaceId: spaceId || currentSpaceId,
      data: informationData,
    });
  };

  const reset = () => {
    setInformationData({
      city: null,
      number: "",
      address: "",
      // description: "abc",
      swiftCode: "",
      iban: "",
      branchName: "",
      bankName: "",
      country: Country?.[0]?.id,
      countryName: Country[0],
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
    if (resultStats?.isSuccess) {
      toast.success("Banking Information Added Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setChange(false);
    }
  }, [resultStats]);

  return (
    <>
      <div className="card">
        <div className="p-4">
          <form
            onSubmit={settingsPermissions[0]?.update ? postInformation : null}
          >
            <div className="row">
              {/* <div className="col-12">
                <div role="group" className="form-group" id="__BVID__1907">
                  <label
                    htmlFor="bio-area"
                    className="d-block mb-1"
                    id="__BVID__1907__BV_label_"
                  >
                    Bio
                  </label>
                  <div>
                    <textarea
                      name="description"
                      id="bio-area"
                      placeholder="Your Bio Data Here..."
                      rows="4"
                      wrap="soft"
                      onChange={handle}
                      value={informationData?.description}
                      className={`form-control ${
                        descriptionValid ? "" : "is-invalid"
                      }`}
                    ></textarea>
                  </div>
                  {!descriptionValid && (
                    <div className="invalid-feedback">
                      Please Enter Description
                    </div>
                  )}
                </div>
              </div> */}

              <div className="col-md-6">
                <div role="group" className="form-group">
                  <label htmlFor="example-country" className="mb-1">
                    Country
                  </label>

                  {/* <Select
                    id="example-country"
                    options={Country?.map((name) => ({
                      label: name,
                      value: name,
                    }))}
                    defaultValue={{
                      label: informationData?.countryName,
                      value: informationData?.countryName,
                    }}
                    isSearchable={true}
                    onChange={handleCountry}
                  /> */}
                  <select
                    name="countryName"
                    value={informationData?.countryName}
                    onChange={(event) => handleCountry(event)}
                    className="form-control"
                    placeholder="Country"
                    style={{
                      WebkitAppearance: "menulist",
                      MozAppearance: "menulist",
                      appearance: "menulist",
                      // Add any other custom styles you need here
                    }}
                  >
                    {Country?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group">
                  <label htmlFor="example-country" className="mb-1">
                    State
                  </label>

                  {/* <Select
                    id="example-country"
                    options={states?.map((name) => ({
                      label: name,
                      value: name,
                    }))}
                    defaultValue={{
                      label: informationData?.stateName,
                      value: informationData?.stateName,
                    }}
                    isSearchable={true}
                    onChange={handleState}
                  /> */}
                  <select
                    name="stateName"
                    id="stateName"
                    className="form-control"
                    placeholder="State "
                    value={informationData?.stateName}
                    onChange={handleChange}
                    style={{
                      WebkitAppearance: "menulist",
                      MozAppearance: "menulist",
                      appearance: "menulist",
                      // Add any other custom styles you need here
                    }}
                  >
                    <option>{informationData?.stateName}</option>
                    {states?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1907">
                  <label htmlFor="city" className="mb-1">
                    City
                  </label>

                  <select
                    name="cityName"
                    value={informationData?.cityName}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="City"
                    style={{
                      WebkitAppearance: "menulist",
                      MozAppearance: "menulist",
                      appearance: "menulist",
                    }}
                  >
                    <option>{informationData?.cityName}</option>

                    {Cities?.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="number" className=" mb-1">
                    Phone
                  </label>
                  <div>
                    <input
                      type="text"
                      id="number"
                      name="number"
                      required
                      value={informationData.number}
                      onChange={handle}
                      placeholder="Phone Number"
                      className={`form-control ${
                        numberValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!numberValid && (
                    <div className="invalid-feedback">Please Enter Number</div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="address" className=" mb-1">
                    Address
                  </label>
                  <div>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      required
                      value={informationData.address}
                      onChange={handle}
                      placeholder="Address"
                      className={`form-control ${
                        addressValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!addressValid && (
                    <div className="invalid-feedback">Please Enter Address</div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1916">
                  <label
                    htmlFor="bankName"
                    className="mb-1"
                    id="__BVID__1916__BV_label_"
                  >
                    Bank Name
                  </label>
                  <div>
                    <input
                      id="bankName"
                      name="bankName"
                      type="text"
                      // required
                      value={informationData?.bankName}
                      onChange={handle}
                      placeholder="Bank Name"
                      className={`form-control ${
                        bankNameValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!bankNameValid && (
                    <div className="invalid-feedback">
                      Please Enter Bank Name
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="branchName" className=" mb-1">
                    Branch Name
                  </label>
                  <div>
                    <input
                      type="text"
                      id="branchName"
                      name="branchName"
                      // required
                      value={informationData.branchName}
                      onChange={handle}
                      placeholder="Branch Name"
                      className={`form-control ${
                        branchNameValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!branchNameValid && (
                    <div className="invalid-feedback">
                      Please Enter Branch Name
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1916">
                  <label
                    htmlFor="iban"
                    className="mb-1"
                    id="__BVID__1916__BV_label_"
                  >
                    Iban
                  </label>
                  <div>
                    <input
                      id="iban"
                      name="iban"
                      type="text"
                      // required
                      value={informationData?.iban}
                      onChange={handle}
                      placeholder="IBAN"
                      className={`form-control ${
                        ibanValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!ibanValid && (
                    <div className="invalid-feedback">Please Enter IBAN</div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1916">
                  <label
                    htmlFor="swiftCode"
                    className="mb-1"
                    id="__BVID__1916__BV_label_"
                  >
                    Swift Code
                  </label>
                  <div>
                    <input
                      // required
                      name="swiftCode"
                      id="swiftCode"
                      value={informationData?.swiftCode}
                      onChange={handle}
                      type="text"
                      placeholder="Swift Code"
                      className={`form-control ${
                        swiftCodeValid ? "" : "is-invalid"
                      }`}
                    />
                  </div>
                  {!swiftCodeValid && (
                    <div className="invalid-feedback">
                      Please Enter SwiftCode
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="accountNumber" className=" mb-1">
                    Account Number
                  </label>
                  <div>
                    <input
                      type="text"
                      id="accountNumber"
                      name="accountNumber"
                      value={informationData.accountNumber}
                      onChange={handle}
                      placeholder="Account Number"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="institutionNumber" className=" mb-1">
                    Institution Number
                  </label>
                  <div>
                    <input
                      type="text"
                      id="institutionNumber"
                      name="institutionNumber"
                      value={informationData.institutionNumber}
                      onChange={handle}
                      placeholder="Institution Number"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="branchCode" className=" mb-1">
                    Branch Code
                  </label>
                  <div>
                    <input
                      type="text"
                      id="branchCode"
                      name="branchCode"
                      value={informationData.branchCode}
                      onChange={handle}
                      placeholder="Branch Code"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="routingNumber" className=" mb-1">
                    Routing Number
                  </label>
                  <div>
                    <input
                      type="text"
                      id="routingNumber"
                      name="routingNumber"
                      value={informationData.routingNumber}
                      onChange={handle}
                      placeholder="Routing Number"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="transitNumber" className=" mb-1">
                    Transit Number
                  </label>
                  <div>
                    <input
                      type="text"
                      id="transitNumber"
                      name="transitNumber"
                      value={informationData.transitNumber}
                      onChange={handle}
                      placeholder="Transit Number"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div role="group" className="form-group" id="__BVID__1918">
                  <label htmlFor="taxNumber" className=" mb-1">
                    Tax Number
                  </label>
                  <div>
                    <input
                      type="text"
                      id="taxNumber"
                      name="taxNumber"
                      value={informationData.taxNumber}
                      onChange={handle}
                      placeholder="Tax Number"
                      className={`form-control`}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <button
                  type="submit"
                  className="btn me-3 btn-primary"
                  style={{ position: "relative" }}
                  disabled={!change || !settingsPermissions[0]?.update}
                >
                  {settingsPermissions[0]?.update ? (
                    "Save changes"
                  ) : (
                    <span>Save changes{Lock2}</span>
                  )}
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary"
                  onClick={settingsPermissions[0]?.update ? reset : null}
                  disabled={!settingsPermissions[0]?.update}
                >
                  {settingsPermissions[0]?.update ? (
                    "Reset"
                  ) : (
                    <span>Reset{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TabInformation;
