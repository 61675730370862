import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import ViewInvoicePopup from "../AddInvoices/ViewInvoicePopup";
import DeleteInvoicePopup from "../AddInvoices/DeleteInvoicePopup";
import UpdateInvoicePopup from "../AddInvoices/UpdateInvoicePopup";
// import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import {
  useGetAllInvoicesQuery,
  useGetInvoicesOfSingleMemberQuery,
  useGetMemberAccessGroupQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { Badge, Pagination } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import invoice1Png from "../../Assets/dummyImages/1.png";
import invoice2Png from "../../Assets/dummyImages/2.png";
import Loader from "../Loader/Loader";
import { useGlobalContext } from "../../Context/GlobalContext";
import { UtcToLocalDateTime } from "../../utils/UtcToLocalDateTime";

const MemberAccessGroup = ({ id }) => {
  const { InvoicesPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [activePage, setActivePage] = useState(1);
  const [status, setStatus] = useState({
    label: "Select All",
    value: 4,
  });
  const [search, setSearch] = useState("");

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [loading, setLoading] = useState(false);
  // const { isLoading: fetchingData, data: FetchSpaceInvoice } =
  //   useGetSpaceInvoiceQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     numberOfItems: itemsPerPage.value,
  //     pageNumber: activePage,
  //     userName: search,
  //     status: status?.value,
  //   });

  const { isLoading: fetchingData, data: FetchSpaceInvoice } =
    useGetInvoicesOfSingleMemberQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
      page_size: itemsPerPage.value,
      page: activePage,
      name: search,
      status: status?.value,
    });

  const { isLoading: fetchingGroups, data: FetchMemberAccessGroups } =
    useGetMemberAccessGroupQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
      page_size: itemsPerPage.value,
      page: activePage,
    });

  console.log(
    "Member Access Groups are ::  ID : ",
    id,
    "  Groups :  ",
    FetchMemberAccessGroups
  );

  useEffect(() => {
    setLoading(false);
  }, [FetchMemberAccessGroups]);

  const { items, requestSort, sortConfig } = useSortableData(
    FetchMemberAccessGroups || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    setActivePage(1);
    setLoading(true);
  };

  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    return date.toLocaleDateString();
  }

  const handlePage = (e) => {
    setitemsPerPage(e);
    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };

  const options = [
    {
      label: 5,
      value: 5,
    },
    {
      label: 10,
      value: 10,
    },
    {
      label: 15,
      value: 15,
    },
    {
      label: 20,
      value: 20,
    },
  ];

  const optionsStatus = [
    {
      label: "Select All",
      value: 4,
    },
    {
      label: "Un Paid",
      value: 0,
    },
    {
      label: "Paid",
      value: 1,
    },
    // {
    //   label: "Past Due",
    //   value: 2,
    // },
    // {
    //   label: "Active",
    //   value: 3,
    // },
  ];
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}`;
  }
  const images = [invoice1Png, invoice2Png];
  if (fetchingData || fetchingGroups) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <section className=" invoice-list-wrapper with-bottom-padding">
        <div className="card">
          <h4 className="mt-3 ml-4">Member Access Group</h4>
          <div
            className="row justify-content-between"
            style={{ padding: "0.5rem" }}
          >
            {/* <div className="col-12 col-md-6 my-md-3 my-lg-0 col-lg-3">
              <Select
                options={optionsStatus}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                placeholder={"Select Status"}
                styles={colorStyles}
              />
            </div> */}
          </div>
          {/* <div
            className="row justify-content-between"
            style={{ padding: "0 1.5rem 0 1.5rem" }}
          >
            <div className="col-12 col-md-4 col-lg-2">
              <Select
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handlePage}
                value={itemsPerPage}
                id="pages-select"
                styles={colorStyles}
              />
            </div>
            <div className="col-12 col-md-6 my-3 my-lg-0 ">
              <input
                type="search"
                className="form-control"
                value={search}
                onChange={handleSearch}
                placeholder="Search Invoice"
              />
            </div>
          </div> */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Group Name</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  {/* <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Price</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("price")}
                          className={getClassNamesFor("price")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("price")}
                          className={getClassNamesFor("price")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Due Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Publish Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Status</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      ></div>
                    </div>
                  </th>
                  <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader height={6.6} />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      const { id, name } = item.group;

                      return (
                        <tr key={index}>
                          <td>{item?.scope == "group" ? name : ""}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {search == "" ? "No Record !" : "No Group found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && FetchMemberAccessGroups?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {FetchMemberAccessGroups?.invoices.length} of{" "}
                  {FetchMemberAccessGroups?.totalCount} entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({
                    length: FetchMemberAccessGroups?.totalPages,
                  }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === activePage}
                      onClick={() => handlePageSelect(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={
                      activePage === FetchMemberAccessGroups?.totalPages
                    }
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default MemberAccessGroup;
